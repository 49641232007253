import React, { Component } from "react"
import { Modal, ButtonToolbar, Button } from "react-bootstrap"

class Welcome extends Component {
  render() {
    return (
      <Modal
        className="Welcome"
        show={this.props.show}
        onHide={this.props.toggle}
      >
        <Modal.Header>
          <Modal.Title componentClass="h1">
            Meta Tic Tac Toe <small>meta.gdt.io</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The game played in math departments everywhere has finally come to
            the browser!
          </p>
          <h3>Rules</h3>
          <ul>
            <li>Win the large board by winning a line of small boards.</li>
            <li>
              Where you go <em>within</em> a small board corresponds to{" "}
              <em>which</em> small board in the large board that your opponent
              must go next.
            </li>
            <li>
              Sending your opponent to a completed small board allows them to go
              anywhere in the large board.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" onClick={this.props.toggle}>
              Continue
            </Button>
            {/*
            <Button href="https://github.com/gregorytoprak/meta-tic-tac-toe/">
              GitHub
            </Button>
            */}
            <Button bsStyle="link" href="https://www.gregorytoprak.com/">
              by GDT
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default Welcome

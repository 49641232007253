import Immutable from 'immutable'

const [ n, m, k, d, c ] = [ 3, 3, 3, 2, 8 ]

export const viewBox = `0 0 ${n ** d} ${m ** d}`

export const initialBoard = (depth = d) =>
  depth === 0
    ? '_'
    : Immutable.Repeat(Immutable.Repeat(initialBoard(depth - 1), n).toList(), m).toList()

const simplifyBase = (board) => {
  const candidates = Immutable.fromJS([m, n, c])
    .map((size) =>
      Immutable.Range(0, size).toList())
    .reduce((axs, bxs) =>
      axs.flatMap((ax) =>
        bxs.map((bx) =>
          Immutable.fromJS([ax, bx])
            .flatten())))
    .map((key) =>
      Immutable.Range(0, k).toList()
        .map((dist) => {
          const coords = Immutable.fromJS([Math.cos, Math.sin])
            .map((trig) =>
              dist * Math.round(trig(2 * Math.PI * key.last() / c)))
            .zipWith((alpha, beta) => alpha + beta, key.butLast())
          const [ rNum, cNum ] = coords.toJS()
          return rNum < 0 || m <= rNum || cNum < 0 || n <= cNum
            ? '_'
            : board.getIn(coords)
        }))
    .filter((line) =>
      line.first() !== '_' && line.every((pt) => pt === line.first()))
  return candidates.isEmpty()
    ? board.flatten().includes('_')
      ? '_'
      : '-'
    : candidates.first().first()
}

export const simplifyFull = (board) => {
  if (!Immutable.List.isList(board)) { return board }
  return simplifyBase(board.map((row) => row.map(simplifyFull)))
}

export const simplify = (board) => {
  if (!Immutable.List.isList(board)) { return board }
  const char = simplifyFull(board)
  return char === '_' || char === '-'
    ? board.map((row) => row.map(simplify))
    : char
}

export const transform = (loc) => {
  const scale = (depth) =>
    Immutable.fromJS([n ** depth, m ** depth])
  const translations = loc.map((coord, i) =>
    coord.zipWith((base, scalar) => base * scalar, scale(d - (i + 1)))
  )
  const translation = translations.reduce((axs, bxs) =>
    axs.zipWith((ax, bx) => ax + bx, bxs),
    Immutable.fromJS([0, 0])
  )

  return `translate(${translation.get(0)} ${translation.get(1)}) scale(${n ** (d - loc.size)} ${m ** (d - loc.size)})`
}

export const divCoords = (dir, num) => {
  const [ breadth, gap ] = [ 1 / 30, 2 / 25 ]
  return dir === 'horizontal'
    ? {
      x: gap,
      y: num / m - breadth / 2,
      width: 1 - 2 * gap,
      height: breadth
    }
    : {
      x: num / n - breadth / 2,
      y: gap,
      width: breadth,
      height: 1 - 2 * gap
    }
}

export const divColor = (loc, header) =>
  header.get('playing') && header.get('next').equals(loc)
    ? header.get('player') === 'X'
      ? '#228'
      : '#822'
    : '#282'

import React from "react"
import PropTypes from "prop-types"
import Immutable from "immutable"

import UnitBoard from "./UnitBoard"
import Divider from "./Divider"

const Board = ({ loc, board, header, makeMove }) => {
  if (!Immutable.List.isList(board)) {
    return <UnitBoard loc={loc} board={board} makeMove={makeMove} />
  }
  return (
    <g>
      {board.map((row, r) =>
        row.map((square, c) => (
          <Board
            loc={loc.push(Immutable.fromJS([r, c]))}
            board={square}
            header={header}
            makeMove={makeMove}
          />
        )),
      )}
      {board.map((_, r) => (
        <Divider loc={loc} key={r} dir="horizontal" num={r} header={header} />
      ))}
      {board.first().map((_, c) => (
        <Divider loc={loc} key={c} dir="vertical" num={c} header={header} />
      ))}
    </g>
  )
}

Board.propTypes = {
  loc: PropTypes.any.isRequired,
  board: PropTypes.any.isRequired,
  header: PropTypes.any.isRequired,
  makeMove: PropTypes.func.isRequired,
}

export default Board

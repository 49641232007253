import React from "react"
import PropTypes from "prop-types"
import Immutable from "immutable"

import Board from "./Board"
import { viewBox } from "../utils"

const Svg = ({ state, makeMove }) => (
  <svg viewBox={viewBox}>
    <Board
      loc={Immutable.fromJS([])}
      board={state.get("board")}
      header={state.get("header")}
      makeMove={makeMove}
    />
  </svg>
)

Svg.propTypes = {
  state: PropTypes.any.isRequired,
  makeMove: PropTypes.func.isRequired,
}

export default Svg

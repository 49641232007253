import React from "react"
import PropTypes from "prop-types"

import Mark from "./Mark"
import { transform } from "../utils"

const UnitBoard = ({ loc, board, makeMove }) => (
  <g transform={transform(loc)} onClick={() => makeMove(loc.toJS())}>
    <rect fill="transparent" x="0" y="0" width="1" height="1" />
    <Mark board={board} />
  </g>
)

UnitBoard.propTypes = {
  loc: PropTypes.any.isRequired,
  board: PropTypes.string.isRequired,
  makeMove: PropTypes.func.isRequired,
}

export default UnitBoard

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Welcome from "./Welcome"
import Svg from "./Svg"
import { move } from "../actions"

class Game extends React.Component {
  state = { showWelcome: true }

  toggleWelcome = () => {
    this.setState({ showWelcome: !this.state.showWelcome })
  }

  render() {
    return (
      <div>
        <Welcome show={this.state.showWelcome} toggle={this.toggleWelcome} />
        <Svg {...this.props} />
      </div>
    )
  }
}

Game.propTypes = {
  state: PropTypes.any.isRequired,
  makeMove: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  state,
})

const mapDispatchToProps = (dispatch) => ({
  makeMove: (loc) => {
    dispatch(move(loc))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Game)

import Immutable from 'immutable'

import { initialBoard, simplifyFull, simplify } from './utils'

const initialState = Immutable.fromJS({
  board: initialBoard(),
  header: {
    next: [],
    player: 'X',
    playing: false
  }
})

const reducer = (state = initialState, action) => {
  const board = state.get('board')
  const header = state.get('header')
  const next = header.get('next')
  const player = header.get('player')
  if (action.type === 'MOVE') {
    const loc = Immutable.fromJS(action.loc)
    if (loc.take(next.size).equals(next) && Immutable.List.isList(board) && board.getIn(loc.flatten()) === '_') {
      const nextBoard = simplify(board.setIn(loc.flatten(), player))
      const makeNext = (potNext) => {
        if (!Immutable.List.isList(nextBoard)) { return potNext.clear() }
        return simplifyFull(nextBoard.getIn(potNext.flatten())) === '_' || potNext.isEmpty()
          ? potNext
          : makeNext(potNext.rest())
      }
      return Immutable.fromJS({
        board: nextBoard,
        header: {
          next: makeNext(loc),
          player: player === 'X' ? 'O' : 'X',
          playing: Immutable.List.isList(nextBoard) && nextBoard.flatten().includes('_')
        }
      })
    }
  }
  return state
}

export default reducer

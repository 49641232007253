import React from "react"
import PropTypes from "prop-types"

const Mark = ({ board }) => {
  switch (board) {
    case "X":
      return (
        <g transform="scale(0.01)">
          <line
            x1="25"
            y1="25"
            x2="75"
            y2="75"
            strokeWidth="10"
            stroke="#228"
          />
          <line
            x1="25"
            y1="75"
            x2="75"
            y2="25"
            strokeWidth="10"
            stroke="#228"
          />
        </g>
      )
    case "O":
      return (
        <g transform="scale(0.01)">
          <circle
            cx="50"
            cy="50"
            r="30"
            strokeWidth="10"
            stroke="#822"
            fill="none"
          />
        </g>
      )
    default:
      return <g />
  }
}

Mark.propTypes = {
  board: PropTypes.string.isRequired,
}

export default Mark

import React from "react"
import PropTypes from "prop-types"

import { transform, divCoords, divColor } from "../utils"

const Divider = ({ loc, dir, num, header }) =>
  num === 0 ? (
    <g />
  ) : (
    <g transform={transform(loc)}>
      <rect {...divCoords(dir, num)} fill={divColor(loc, header)} />
    </g>
  )

Divider.propTypes = {
  loc: PropTypes.any.isRequired,
  dir: PropTypes.string.isRequired,
  num: PropTypes.number.isRequired,
  header: PropTypes.any.isRequired,
}

export default Divider
